<template>
<div class="shop grid">
    <ShopSubmenu :active-tab="activeTab" />
    <ShopGames v-if="activeTab == 'games'" />
    <ShopMerch v-if="activeTab == 'merch'" />
    <ShopGiftCards v-if="activeTab == 'giftcards'" />
</div>
</template>

<script setup lang="ts">
import ShopGames from '$components/shopping/ShopGames.vue'
import ShopMerch from '$components/shopping/ShopMerch.vue'
import ShopGiftCards from '$components/shopping/ShopGiftCards.vue'
import ShopSubmenu from '$components/shopping/ShopSubmenu.vue'

const app = useNuxtApp()
const i18n = useI18n()
const activeTab = computed(() => app.$getParams().tab || 'games')
const description = computed(() => {
    if (app.$getParams().tab === 'games') {
        return 'META.SHOP_GAMES'
    }

    if (app.$getParams().tab === 'merch') {
        return 'META.SHOP_MERCH'
    }

    return 'META.SHOP'
})

useHead({
    title: `${i18n.t('NAVBAR.SHOP')}`,
    link: [
        {
            rel: 'canonical',
            href: 'https://www.jackboxgames.com/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'x-default',
            href: 'https://www.jackboxgames.com/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'en',
            href: 'https://www.jackboxgames.com/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'fr',
            href: 'https://www.jackboxgames.com/fr/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'it',
            href: 'https://www.jackboxgames.com/it/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'de',
            href: 'https://www.jackboxgames.com/de/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'es-ES',
            href: 'https://www.jackboxgames.com/es/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'es',
            href: 'https://www.jackboxgames.com/es/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'es-MX',
            href: 'https://www.jackboxgames.com/es-XL/shop'
        },
        {
            rel: 'alternate',
            hreflang: 'pt-BR',
            href: 'https://www.jackboxgames.com/pt-BR/shop'
        }
    ],
    meta: [
        { name: 'description', content: `${i18n.t(description.value)}` }
    ]
})

</script>
